<template>
  <main>
    <sqr-hero
      title="password_lost_title"
      subtitle="password_lost_subtitle"
      color="primary"
      size="small"
      is-bold
    />
    <section class="section">
      <div class="container">
        <div class="columns is-centered">
          <div class="column is-half">
            <div class="card" v-if="!success">
              <div class="card-content">
                <h1 class="title">{{$t('password_lost_title')}}</h1>
                <error-notifications :errors="errors"/>
                <div class="field">
                  <sqr-input-email
                    name="email"
                    :value="email"
                    @change="fieldSet({name: 'email', value: $event})"
                    label="password_lost_email"
                    size="large"
                  />
                </div>
                <div class="buttons is-right">
                  <sqr-button
                    label="password_lost_confirm"
                    color="primary"
                    size="large"
                    @click="requestAndGo()"
                    :is-loading="loading"
                  />
                </div>
              </div>
            </div>
            <div v-if="success">
              <div class="notification is-success is-size-4">
                <fa :icon="['fal', 'check-circle']"/>
                &#8200;
                <span>{{$t('password_lost_success')}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

import SqrInputEmail from '@/sqrd/SqrInputEmail';
import SqrButton from '@/sqrd/SqrButton';

import ErrorNotifications from './ErrorNotifications';

export default {
  name: 'PasswordLost',
  components: {
    SqrButton,
    SqrInputEmail,
    ErrorNotifications
  },
  computed: {
    ...mapGetters('auth', ['email'])
  },
  data() {
    return {
      loading: false,
      success: true
    };
  },
  methods: {
    ...mapMutations('auth', ['fieldSet']),
    async requestAndGo() {
      try {
        this.loading = true;
        const email = this.email;
        await this.$fb().auth().sendPasswordResetEmail(email);
        this.success = true;
      } catch (error) {
        console.log(error);
        this.loading = false;
      }
    }
  }
};
</script>
